<template>
    <v-row style="text-align: left">
        <v-col cols="1" md="2"></v-col>
        <v-col cols="10" md="8">
            <h2 style="text-align: center">Amenities</h2>
            <v-divider/>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-list disabled flat>
                        <v-list-item
                            v-for="(item, i) in leftItems"
                            :key="i"
                        >
                            <!-- <v-icon  v-if="$vuetify.breakpoint.smAndUp" left  v-text="item.icon" :color="item.color"></v-icon> -->
                            <!-- <v-icon left  v-text="item.icon" :color="item.color"></v-icon>  -->
                            <!-- <v-list-item-content> -->
                            <!-- <v-list-item-title :class="$vuetify.breakpoint.smAndUp ? '': 'xs'"  v-text="item.text"></v-list-item-title> -->
                            <p>{{item.text}}</p>
                            <!-- </v-list-item-content> -->
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-list disabled flat>
                        <v-list-item
                            v-for="(item, i) in rightItems"
                            :key="i"
                        >
                            <!-- <v-icon left v-text="item.icon" :color="item.color"></v-icon> -->
                            <!-- <v-list-item-content> -->
                            <!-- <v-list-item-title :class="$vuetify.breakpoint.smAndUp ? '': 'xs'" v-text="item.text"></v-list-item-title> -->
                            <p>{{item.text}}</p>
                            <!-- </v-list-item-content> -->
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
            <!-- <h2 style="padding-top: 25px">Details</h2> -->
            <!-- <v-divider/>
            <v-row> 
                <v-col cols="6">
                    <h3>Ceremony Types</h3>
                    <p>
                        Civil Union, Commitment Ceremony,
                        Elopement, Interfaith Ceremony,
                        Non-Religious Ceremony, Second Wedding,
                        Vow Renewal Ceremony
                    </p>
                </v-col>
                <v-col cols="6">
                    <h3>Settings</h3>
                    <p>
                        Backyard, Barn, Farm &#x26; Ranch, Estate, 
                        Industrial &#x26; Warehouse, Loft, Religious Setting, 
                        Tented, Trees
                    </p>
                </v-col>
                <v-col cols="6">
                    <h3>Venue Service Offerings</h3>
                    <p>
                        Bar &#x26; Drinks, Bar Rental, Cakes &#x26; Desserts, Cupcakes, 
                        Other Desserts, Destination Weddings, Destination Wedding Packages, 
                        Destination Wedding Planning, Planning, Rentals &#x26; Equipment
                    </p>
                </v-col>
                <v-col cols="6">
                    <h3>Guest Capacity</h3>
                    <p>
                        Up to 300
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
        <v-col cols="1" md="2"></v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
      leftItems: [
        { text: 'East side of Sioux Falls overlooking Sioux - River 2400 S. Riverview Avenue Sioux Falls, South Dakota 57110', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Holds 330 guests', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Wooden timber construction with exposed beams - Legacy Post & Beam', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Music and bar close at 11:30 p.m.', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Bridal Suite with attached bathroom', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Groom\'s Den', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Open catering policy', icon: 'mdi-circle-medium' , color: 'brown'},
      ],
      rightItems: [
        { text: 'Tables and chairs included', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Surround sound', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Full-service kitchen', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Beer, wine, seltzers, pilsners, hard seltzer, wine-based liquor available for purchase', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: '105 paved parking spots', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Paved road to Highway 11/42', icon: 'mdi-circle-medium' , color: 'brown'},
        { text: 'Bartenders and manager on duty', icon: 'mdi-circle-medium' , color: 'brown'},
      ],
    })
}
</script>

<style scoped>
.xs {
    font-size: 16px;
}
</style>